import { dateTimeInFormatYmdhis } from "../../dateService";
import { LocalStorageService } from "../../LocalStorageService";

export class BipagemService {
  insumosBipados = [];
  insumo;
  index;
  vm;
  event;
  input;
  log;
  produto;
  insumoParaRequest;
  localStorageService;

  constructor(localStorageService) {
    this.localStorageService = localStorageService;
  }

  static build() {
    return new BipagemService(LocalStorageService.build());
  }

  getInsumosBipados() {
    return this.insumosBipados;
  }

  setInsumo(insumo) {
    this.insumo = insumo;
    return this;
  }

  setIndex(index) {
    this.index = +index;
    return this;
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  setEvent(event) {
    this.event = event;
    return this;
  }

  setLog() {
    this.log = document.querySelector(".log");
  }

  run() {
    try {
      this.validateBipagem();
    } catch (error) {
      console.error(error.message);
    }
  }

  validateBipagem() {
    this.setInput();
    this.validateIfEnterWasPressed();
    setTimeout(() => {
      this.validateInput();
      this.validateIfValorDigitadoEUnico();
      this.validarSeValorJaFoiBipadoNoBackend();
      this.setInsumoParaRequest();
      this.validarSeSerialJaEstaContidoNaListaDeBipagens();
      this.avaliarAvancoParaProximoInput();
    }, 10);
  }

  setInput() {
    this.input = document.querySelector(".prod-cod-" + this.insumo.NUMSEQ);
  }

  validateIfEnterWasPressed() {
    const enterWasPressed = [0, 13, 229].includes(this.event.keyCode);
    if (!enterWasPressed) {
      throw new Error(
        "O enter não foi pressionado. Portanto, não pode avançar."
      );
    }
  }

  validateInput() {
    const inputIsValid =
      !this.input.disabled &&
      this.input.value.length > 4 &&
      this.input.value.trim().length > 0;
    if (!inputIsValid) {
      this.input.disabled = false;
      throw new Error(
        "O input não está pronto para ser avaliado para bipagem."
      );
    }
  }

  validateIfValorDigitadoEUnico() {
    if (!this.valorDigitadoEUnico()) {
      this.cleanInput();
      throw new Error("O serial digitado não é único.");
    }
  }

  valorDigitadoEUnico() {
    const inputsBipagens = Array.from(document.querySelectorAll(".prod-cod"));
    // eslint-disable-next-line no-unused-vars
    return !inputsBipagens.some((inputTeste, i) => {
      if (inputTeste.classList.contains("prod-cod-" + this.insumo.NUMSEQ))
        return false;
      if (inputTeste.value.trim().length === 0) return false;
      return (
        (this.input.value || "").trim() === (inputTeste.value || "").trim()
      );
    });
  }

  validarSeValorJaFoiBipadoNoBackend() {
    const insumosComMesmoNumeroSerieDigitado = this.vm.insumosOk.filter(
      // eslint-disable-next-line no-undef
      (pedido) => pedido.NUMEROSSERIECONTROLADOS.trim() === input.value.trim()
    );
    if (insumosComMesmoNumeroSerieDigitado.length > 0) {
      this.cleanInput();
      throw new Error();
    }
  }

  cleanInput() {
    this.input.value = "";
    this.input.disabled = false;
    this.input.focus();
  }

  validarSeSerialJaEstaContidoNaListaDeBipagens() {
    const insumoIndex = this.insumosBipados.findIndex(
      (insumo) => insumo.item === this.insumo.NUMSEQ
    );
    const inputAtualJaFoiBipado = insumoIndex !== -1;
    if (inputAtualJaFoiBipado) {
      this.insumosBipados[insumoIndex] = this.insumoParaRequest; // Substitui serial bipado
      return;
    }
    this.detectarErroBipagem();
    this.insumosBipados.push(this.insumoParaRequest);
  }

  setInsumoParaRequest() {
    const cod = this.input.value.trim();
    const item = this.insumo.NUMSEQ;
    const codProdMp =
      (
        this.insumosBipados.find(
          (insumo) => +insumo.NUMSEQ === +this.insumo.NUMSEQ
        ) || {}
      ).CODPRODMP || -1;
    const dbegin = this.vm.horaInicialBipagem;
    const dend = dateTimeInFormatYmdhis();
    this.insumoParaRequest = { cod, item, codProdMp, dbegin, dend };
    this.storeSerialOnLocalStorage();
  }

  storeSerialOnLocalStorage() {
    const serial = this.input.value.trim();
    const { className } = this.input;
    this.localStorageService.storeSerialBipado(serial, className);
  }

  detectarErroBipagem() {
    for (let i = this.insumosBipados.length - 1; i >= 0; i--) {
      const codProdMpInsumoIteracaoAtual = this.insumosBipados[i].codProdMp;
      const codProdMpSerialBipado = this.insumoParaRequest.codProdMp;
      if (codProdMpInsumoIteracaoAtual !== codProdMpSerialBipado) continue;
      const contemErroBipagem =
        !this.seriaisTemMesmoTamanho(i) || !this.seriaisTemMesmasIniciais(i);
      if (contemErroBipagem) {
        alert(
          "Provavelmente aconteceu um erro na bipagem. Favor, conferir se o número bipado corresponde ao número correto do painel."
        );
        break;
      }
    }
  }

  seriaisTemMesmoTamanho(i) {
    const lengthSerialBipado = this.insumoParaRequest.cod.length;
    const lengthSerialIteracaoAtual = this.insumosBipados[i].cod.length;
    return lengthSerialBipado === lengthSerialIteracaoAtual;
  }

  seriaisTemMesmasIniciais(i) {
    const iniciaisSerialBipado = this.insumoParaRequest.cod.substr(0, 2);
    const iniciaisSerialIteracaoAtual = this.insumosBipados[i].cod.substr(0, 2);
    return iniciaisSerialBipado === iniciaisSerialIteracaoAtual;
  }

  avaliarAvancoParaProximoInput() {
    this.vm.$nextTick(() => {
      this.vm.horaInicialBipagem = dateTimeInFormatYmdhis();
      this.avancarParaProximoInput();
    });
  }

  avancarParaProximoInput() {
    const index = this.getNumSeqInput() + 1;
    const className = ".prod-cod-" + index;
    const nextInput = document.querySelector(className);
    if (nextInput) {
      nextInput.focus();
    } else {
      const btnSubmit = document.querySelector("#btn-enviar-linha-1");
      btnSubmit.focus();
    }
    this.input.disabled = false;
  }

  getNumSeqInput() {
    return +this.input.className.replace(/\D+/, "");
  }
}
