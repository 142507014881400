<template>
  <span>
    <div
      v-for="(volume, index) in volumes"
      :key="index"
    >
      <div
        class="mb-3 px-1 py-4"
        v-show="!volume.codigo"
      >
        <b-card
          class="shadow"
          header="featured"
          header-tag="div"
          :header-class="
            'cor-background-azul ' +
            (btnFecharCaixaLoading ? ' caixa-carregando' : '')
          "
        >
          <template #header>
            <h4
              class="mb-6 font-weight-bold"
              style="color: white"
            >
              Caixa {{ index + 1 }} - {{ volume.codigo }}
            </h4>
          </template>
          <b-row
            v-for="(volume, k) in volume.insumos"
            :key="k"
            class="my-4 px-1 py-1"
            style="border-bottom: 1px solid black"
            align-v="center"
          >
            <b-col cols="12 mb-n6">
              <label
                style="
                  align-items: center;
                  font-family: 'Poppins';
                  font-size: 20px;
                "
                >{{ (volume.insumo || {}).descricao || "" }}</label
              >
            </b-col>
            <b-col
              cols="12"
              sm="7"
              lg="8"
            >
              <listagem-insumos-no-volume-linha-perfis
                :insumo="volume.insumo"
                :volumes="volumes"
                :indexVol="index"
                :total-insumos="insumos.length"
                :loading="loading"
                v-on="$listeners"
                @biparInsumo="$emit('biparInsumo')"
              />
            </b-col>
            <b-col
              cols="12"
              sm="5"
              lg="4"
            >
              <div>
                <b-button
                  class="number-modifier"
                  @click="volume.quantidade--"
                  style="border-radius: 8px 0px 0px 8px !important"
                  pill
                  :disabled="btnFecharCaixaLoading || !volume.insumo.bipado"
                >
                  -
                </b-button>
                <input
                  class="py-0 px-0; my-0 mx-0 input-metragem text-center"
                  v-model="volume.quantidade"
                  type="number"
                  :disabled="btnFecharCaixaLoading || !volume.insumo.bipado"
                />
                <b-button
                  class="number-modifier"
                  @click="volume.quantidade++"
                  style="border-radius: 0px 8px 8px 0px !important"
                  pill
                  :disabled="btnFecharCaixaLoading || !volume.insumo.bipado"
                >
                  +
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
  </span>
</template>

<script>
import ListagemInsumosNoVolumeLinhaPerfis from "./ListagemInsumosNoVolumeLinhaPerfis.vue";

export default {
  name: "ListagemCaixasLinhaPerfis",
  components: {
    ListagemInsumosNoVolumeLinhaPerfis,
  },
  props: {
    volumes: {
      type: Array,
      required: true,
    },
    insumos: {
      type: Array,
      required: true,
    },
    btnFecharCaixaLoading: {
      type: Boolean,
      required: true,
    },
    loading: { type: Boolean },
  },
};
</script>

<style scoped>
.cor-background-azul {
  background: #002233;
}
.number-modifier {
  border-style: none;
  background: #ff7d27;
}

.input-metragem {
  border-top: 0px;
  border-bottom: 0px;
  border-right: 0px;
  border-left: 0px;
  width: 80%;
  border-radius: 0px 0px 0px 0px;
}

.caixa-carregando {
  animation: animate 1s linear infinite;
}
@keyframes animate {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}
</style>
